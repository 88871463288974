:root {
	--page-padding-x: 30px;
	--page-padding-y: 30px;

	--header-height: max(60px, 8vh);
	--footer-height: max(100px, 9vh);

	--card-min-height: 20vh;

	--card-radius: 10px;
	--button-radius: 7.5px;

	--accent-color: rgb(131, 67, 173);
	--fg-accent-color: #fafafa;

	--border-color: rgba(50, 50, 50, 0.5);

	--card-bg: radial-gradient(circle at 25% 75%, rgba(25, 25, 25, 0.75), rgba(25, 25, 25, 0.15));
	--card-bg-accent: radial-gradient(circle at 25% 75%, rgba(25, 25, 25, 0.25), rgba(25, 25, 25, 0));

	--card-border-color: rgba(50, 50, 50, 0.5);
	--card-border-color-accent: rgba(78, 0, 129, 0.5);

	--button-font-size: max(14px, 0.85em);
	--button-bg: radial-gradient(circle at 25% 100%, rgba(25, 25, 25, 1), rgba(25, 25, 25, 0.25));
	--button-bg-alt: rgba(250, 250, 250, 1);
	--button-bg-overlay: transparent;
	--button-bg-accent-overlay: transparent;

	--button-bg-hover: radial-gradient(circle at 25% 75%, rgba(25, 25, 25, 1), rgba(25, 25, 25, 0.75));
	--button-bg-hover-alt: rgba(250, 250, 250, 0.75);
	--button-bg-hover-accent-overlay: radial-gradient(circle at 25% 75%, rgba(25, 25, 25, 1), rgba(25, 25, 25, 0.75));

	--button-border-color: rgba(50, 50, 50, 0.25);
	--button-border-color-alt: rgba(255, 255, 255, 0.25);
	--button-border-color-overlay: rgba(50, 50, 50, 0.2);
	--button-border-color-accent-overlay: rgba(250, 250, 250, 0.2);

	--input-font-size: max(14px, 0.85em);
	--input-height: 50px;
	--input-radius: 10px;
	--input-padding-x: 20px;
	--input-padding-y: 0px;
	--input-padding: var(--input-padding-y) var(--input-padding-x);
	--input-bg: radial-gradient(circle at 25% 100%, rgba(25, 25, 25, 0.5), rgba(25, 25, 25, 0.1));
	--input-border-color: rgba(50, 50, 50, 0.75);

	--list-border-color: rgba(50, 50, 50, 0.5);
}

select {
	appearance: none;
	height: var(--input-height);
	border-radius: var(--input-radius);
	padding: var(--input-padding);
	box-sizing: border-box;
	width: 100%;

	color: currentColor;
	background: var(--input-bg);
	font-weight: 600;
	font-size: var(--input-font-size);
	border: 2px solid var(--input-border-color);
	outline: none;

	font-family: inherit;
}

select option {
	color: black;
}

input[type="date"] {
	height: var(--input-height);
	width: 100%;
	border-radius: var(--input-radius);
	padding: var(--input-padding);
	box-sizing: border-box;

	color: currentColor;
	background: var(--input-bg);
	font-weight: 600;
	font-size: var(--input-font-size);
	border: 2px solid var(--input-border-color);
	outline: none;

	font-family: inherit;
}

::-webkit-calendar-picker-indicator {
	filter: invert(1);
}

input[type="text"],
input[type="number"],
input[type="email"],
input[type="password"] {
	appearance: none;
	height: var(--input-height);
	width: 100%;
	border-radius: var(--input-radius);
	padding: var(--input-padding);
	box-sizing: border-box;

	color: currentColor;
	background: var(--input-bg);
	font-weight: 600;
	font-size: var(--input-font-size);
	border: 2px solid var(--input-border-color);
	outline: none;

	font-family: inherit;
}

.inputIconed {
	position: relative;
}

.inputIconed.collapsed {
	height: 0;
	overflow: hidden;
}

.inputIconed>.icon {
	position: absolute;
	right: 2px;
	top: 2px;
	bottom: 2px;
	height: calc(var(--input-height) - 4px);
	width: var(--input-height);
	/* background: var(--input-border-color); */
	border-radius: 0 calc(var(--input-radius) - 2px) calc(var(--input-radius) - 2px) 0;

	display: flex;
	align-items: center;
	justify-content: center;

	opacity: 0.75;
}


.inputIconed.disabled>.icon {
	opacity: 0;
}

.inputIconed>input[type="text"],
input[type="number"] {
	padding-right: calc(var(--input-height));
}

.inputIconed.disabled>input[type="text"],
input[type="number"] {
	border: none;
	background: none;
	pointer-events: none;
	font-size: 1em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	display: flex;
	align-items: center;
	gap: 15px;
	user-select: none;
}

button {
	background: var(--button-bg);
	padding: 10px 20px;
	height: 45px;
	outline: none;
	border: 1px solid var(--button-border-color);
	border-radius: var(--button-radius);
	color: inherit;
	font-family: inherit;
	font-weight: 600;
	user-select: none;
	font-size: var(--button-font-size);

	transition: all linear 0.2s;
}

button>svg {
	min-width: 16px;
	min-height: 16px;
}

button.alt2 {
	background: var(--button-bg-alt);
	color: #010101;
	border-color: var(--button-border-color-alt);
}

button.alt {
	background: var(--button-bg-alt);
	height: 45px;
	box-sizing: border-box;
	border-radius: 22.5px;
	color: #010101;
	border-color: var(--button-border-color-alt);
}

button.overlay {
	background: var(--button-bg-overlay);
	border: 1px solid var(--button-border-color-overlay);
}

button.title {
	background: transparent;
	border: none;
	animation: fadeIn linear 0.2s forwards 1;
}

button.icon {
	padding: 10px;
	height: 40px;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
}

button.icon>svg {
	width: 20px;
	height: 20px;
	pointer-events: none;
}

button.icon.refreshing > svg{
    animation: refreshIcon forwards 1s;
    animation-iteration-count: 3;
}

button.overlay.accent {
	background: var(--button-bg-accent-overlay);
	border: 1px solid var(--button-border-color-accent-overlay);
}

button:active {
	background: var(--button-bg-hover);
}

button.alt:active {
	background: var(--button-bg-hover-alt);
}

button.alt2:active {
	background: var(--button-bg-hover-alt);
}

div.title {
	min-height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: stretch;
}

div.title>h3 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
}

span.title {
	font-size: 1.75em;
	font-weight: 700;
}

span.subtitle {
	opacity: 0.75;
}

span.sectionHeading {
	font-size: 1.5em;
	font-weight: 700;
	margin: 10px 0;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	25% {
		opacity: 0.2;
	}

	50% {
		opacity: 0.5;
	}

	75% {
		opacity: 0.7;
	}

	100% {
		opacity: 1;
	}
}

@keyframes refreshIcon {
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
}

.App {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: center;
	height: 100vh;
	max-height: 100vh;
	min-width: 100vw;
	width: 100vw;
	max-width: 100vw;
	overflow: hidden;

	color: white;

	position: relative;

	background: black;
	overscroll-behavior-y: none;
}

.footerIndicatorGradient {
	position: absolute;
	width: 200vw;
	aspect-ratio: 1;
	background: radial-gradient(circle at center, rgba(255, 255, 255, 0.125) 0%, rgba(255, 255, 255, 0) 50%);

	bottom: 0%;
	transform: translate(0, calc(50% - (var(--header-height)/2) + 30px));

	transition: all linear 0.1s;
	z-index: 0;
}

.footerIndicatorGradient.left {
	left: -75%;
}

.footerIndicatorGradient.middle {
	left: -50%;
}

.footerIndicatorGradient.right {
	left: -25%;
}

.App-logo {
	height: 50%;
	aspect-ratio: 1;
	pointer-events: none;

	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

header {
	/* background-color: #282c34; */
	height: var(--header-height);

	position: relative;
	padding: 0 5%;
	backdrop-filter: blur(5px);
	background: linear-gradient(rgba(5, 5, 5, 0.75), rgba(255, 255, 255, 0));

	z-index: 2;
}

.App-link {
	color: #61dafb;
}

div.page {
	padding: var(--page-padding-y) var(--page-padding-x);
	animation: fadeIn linear 0.2s forwards 1;
	height: 100%;
	/* min-height: calc( 100vh - var(--footer-height) ); */
	/* overflow-y: auto; */
	box-sizing: border-box;
	padding-top: 10px;
}

div.subpage {
	flex: 1;
	animation: fadeIn linear 0.2s forwards 1;
}

div.pagetitle {
	box-sizing: border-box;
	padding: calc(var(--page-padding-y)) var(--page-padding-x) 10px var(--page-padding-x);
	/* margin: calc(-1 * var(--page-padding-y)) calc(-1 * var(--page-padding-x)) -10px calc(-1 * var(--page-padding-x)); */
	font-size: 1.1em;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	z-index: 999;
	background: linear-gradient(rgba(5, 5, 5, 0.75), rgba(255, 255, 255, 0));
	backdrop-filter: blur(10px);

	display: flex;
	align-items: center;
	justify-content: space-between;
}

div.pagetitle>h2 {
	min-height: 40px;
	align-items: stretch;
}

div.pagetitle>h2>span {
	display: flex;
	align-items: center;
	transition: all linear 0.2s;

	animation: fadeIn linear 0.2s forwards 1;
}

div.pagetitle>h2>span.backButton {
	width: 40px;
	margin-right: 0px;
	display: flex;
	align-items: center;
	justify-content: center;
	
	margin-left: calc((-1 * var(--page-padding-x)) / 2);
}

div.pagetitle>h2>span.collapseBackButton {
	width: 0;
	overflow: hidden;
	margin-right: -10px;
}

div.buttonGroup {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 5px;
}

div.greetings {
	min-height: 40px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.Content {
	flex: 1;

	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 0 var(--footer-height) 0; /* var(--header-height) */
	margin: 0 0 calc(-1*var(--footer-height)) 0; /* calc(-1*var(--header-height)) */

	z-index: 1;
}

.menuButton {
	height: 100%;
	aspect-ratio: 1;

	background: none;
	border: none;
	padding: 27.5px;
	outline: none;
	box-sizing: border-box;
}

.menuButton:active {
	background: none;
}

.menuButton svg {
	width: 100%;
	height: 100%;
	color: white;
	opacity: 0.3;
}

.menuButton.active svg {
	opacity: 1;
	filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 1));
}

footer {
	/* background-color: #282c34; */
	height: var(--footer-height);

	color: white;

	position: relative;
	padding: 0px 5%;
	padding-bottom: 20px;
	box-sizing: border-box;
	z-index: 999;

	display: flex;
	align-items: center;
	justify-content: space-around;
	background: linear-gradient(rgba(255, 255, 255, 0), rgba(5, 5, 5, 0.75));
	backdrop-filter: blur(10px);
	border-top: 1px solid var(--border-color);
}

footer.collapseFooter {
	max-height: 0;
	overflow: hidden;
	opacity: 0;
	pointer-events: none;
	padding: 0;
}

.popup-overlay {
	animation-name: modalBGFadeIn;
	animation-duration: 0.1s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
}

@keyframes modalBGFadeIn {
	0% {
		background: rgba(0, 0, 0, 0);
		backdrop-filter: blur(0px);
	}

	100% {
		background: rgba(0, 0, 0, 0.75);
		backdrop-filter: blur(5px);
	}
}

.modal {
	background: #0a0a0a;
	box-sizing: border-box;
	padding: 20px;
	width: 90vw;
	min-height: 25vh;

	color: white;
	border-radius: var(--card-radius);
	border: 1px solid var(--card-border-color);

	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: flex-start;
	gap: 15px;

	animation-name: modalFadeIn;
	animation-duration: 0.1s;
	animation-timing-function: ease-in;
	animation-fill-mode: forwards;
}


@keyframes modalFadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.modal-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 15px;
}