.homeContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    
    text-align: left;
}

.homeContainer h1,h2,h3,h4,h5,h6{
    margin: 0;
}

.homeContainer .monthlySummaryContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
}

.homeContainer .monthlySummaryContainer .monthlySummary{
    border-radius: var(--card-radius);
    height: var(--card-min-height);
    box-sizing: border-box;
    padding: 15px 25px;

    background: var(--accent-color) var(--card-bg-accent);
    color: var(--fg-accent-color);
    border: 1px solid var(--card-border-color-accent);

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;
}

.homeContainer .monthlySummaryContainer .monthlySummary .refreshSummaryBtn {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeContainer .monthlySummaryContainer .monthlySummary .refreshSummaryBtn > svg{
    width: 20px;
    height: 20px;
}

.homeContainer .monthlySummaryContainer .monthlySummary .refreshSummaryBtn.refreshing > svg{
    animation: refreshIcon forwards 1s;
    animation-iteration-count: 3;
}

@keyframes refreshIcon {
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
}

.homeContainer .monthlySummaryContainer .monthlySummary .monthlySummaryDetails{
    flex: 2.5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: stretch;
    gap: 10px;
}

.homeContainer .quickActionsContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
}

.homeContainer .quickActionsContainer .quickActions{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}

.homeContainer .quickActionsContainer .quickActions button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7.5px;
    padding: 0 10px;
}

.homeContainer .accountsSummaryContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
}

.homeContainer .accountsSummaryContainer .accountsSummary{
    position: relative;
    border-radius: var(--card-radius);
    height: var(--card-min-height);
    box-sizing: border-box;
    padding: 25px;

    background: var(--card-bg);
    border: 1px solid var(--card-border-color);

    font-size: medium;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 15px;
}

.homeContainer .accountsSummaryContainer .accountsSummary .showACDetails{
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.homeContainer .accountsSummaryContainer .accountsSummary .showACDetails > svg{
    width: 20px;
    height: 20px;
}