.profileContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    text-align: left;

    box-sizing: border-box;
}

/* Profile Menu Page */

.menuItemsList{
    margin: 10px calc(-1 * var(--page-padding-x));
}

.userMenuItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 15px var(--page-padding-x);
    border-bottom: 1px solid var(--list-border-color);
    user-select: none;
}

.userMenuItem:active{
    background: rgba(255, 255, 255, 0.2);
}

.userMenuItem:last-child{
    border-bottom: 0px;
}

.userMenuItem > .icon{
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.userMenuItem > .icon > svg{
    height: 24px;
    width: 24px;
}

.userMenuItem > .userMenuItemText{
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: stretch;
    gap: 3px;
}

.userMenuItemText > .userMenuItemTitle{
    font-weight: 700;
    font-size: 1.05em;
}

.userMenuItemText > .userMenuItemDescription{
    font-weight: 500;
    font-size: 0.75em;
    opacity: 0.5;
}

/* User Profile Page */

.UserProfilePage{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 10px;
}

.updateButtonGroup{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 15px 0;
}