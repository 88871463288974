.financesContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    text-align: left;

    box-sizing: border-box;
}

/* Summary Page */

.financesSummaryContainer{
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    opacity: 0;

    animation: fadeIn linear 0.2s forwards 1;
    margin-bottom: calc( -1 * (var(--footer-height)) );

    /* max-height: calc(100vh - (var(--header-height) + var(--footer-height)) - 40px); */
}

.financesSummaryContainer .refreshFinancesBtn.refreshing > svg{
    animation: refreshIcon forwards 1s;
    animation-iteration-count: 3;
}

@keyframes refreshIcon {
    0%{
        transform: rotateZ(0deg);
    }
    100%{
        transform: rotateZ(360deg);
    }
}

.financesSummaryContainer .monthlySummary{
    border-radius: var(--card-radius);
    /* height: var(--card-min-height); */
    flex: 1;
    box-sizing: border-box;
    padding: 15px 25px;

    background: var(--card-bg);
    border: 1px solid var(--card-border-color);

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 25px;

    user-select: none;
}

.financesSummaryContainer .transactionList{
    /* flex: 1; */
    overflow-y: auto;
    margin: 0 calc(-1 * var(--page-padding-x));
    padding-bottom: calc(var(--footer-height));
    /* margin-bottom: calc( -1 * (var(--footer-height)) ); */
    border-top: 1px solid var(--border-color);
    max-height: calc(25vh + var(--footer-height));
}

.transactionItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 8vh;
    border-bottom: 0.5px solid var(--list-border-color);
    padding: 0 30px;
    user-select: none;
}

.transactionItem:last-child{
    border-bottom: none;
}

.transactionItem span.left{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;

    font-size: 0.9em;
}


.transactionItem span.left > svg{
    width: 20px;
    height: 20px;
}

.transactionItem .expenseAmount{
    color: rgba(255, 51, 0, 0.75);
    font-weight: 500;
}

.transactionItem .refundAmount{
    color: rgba(115, 255, 0, 0.75);
    font-weight: 500;
}