.alertMobileContainer{
    border-radius: var(--card-radius);
    border: 2px solid var(--card-border-color);
    background-color: black;
    color: white;
    width: 90vw;
    box-sizing: border-box;
    padding: 15px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 10px;

    animation-name: fadeIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.alertMobileContainer .alertMobileTitle{
    font-weight: 500;
    font-size: 1.2em;
    padding: 0 5px;
    opacity: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.alertMobileContainer .alertMobileTitle .Success{
    color: yellowgreen;
}

.alertMobileContainer .alertMobileTitle .Error{
    color: crimson;
}

.alertMobileContainer .alertMobileBody{
    font-weight: 400;
    font-size: 1em;
    padding: 0 5px;
    opacity: 0.8;
}

.alertMobileContainer .alertMobileActions{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}


.alertMobileContainer .alertMobileActions button{
    border: none;
    background: none;
    font-size: 0.9em;
    padding: 7.5px 15px;
    border-radius: 2.5px;
    height: auto;
    opacity: 1;
}