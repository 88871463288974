.loginContainer{
    box-sizing: border-box;
    text-align: center;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 10px;
}

.loginRegisterForm{
    padding: 25px 0;
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    gap: 15px;
}

.loginContainer .buttonGroup{
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.buttonGroup.reversed{
    flex-direction: row-reverse;
}

.buttonGroup > button{
    flex: 1;
    font-size: 0.8em;
}

.altLoginContainer{
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}