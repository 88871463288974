.registerTransactionContainer{
    box-sizing: border-box;
    text-align: left;
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 20px;
    opacity: 0;

    padding: 10px 0;
    animation: fadeIn linear 0.2s forwards 1;
}

.registerTransactionContainer .transactionDetails{
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    gap: 10px;
}

.registerTransactionContainer .transactionDetails .twocolumninputs{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
}